@import url('https://fonts.googleapis.com/css2?family=Roboto');

.App {
  text-align: center;
  font-family: 'Roboto';
  color: #f6f6f6; /* Const.White */
  background: #1f1f1f; /* Const.Gray31 */
}

* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
  background: #1f1f1f; /* Const.Gray31 */
}
